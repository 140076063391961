import { EMPTY, Observable, throwError } from 'rxjs';
import { HttpParams, HttpProgressEvent, HttpResponse, HttpUserEvent} from '@angular/common/http';
import {
     HttpEvent,
     HttpHandler,
     HttpHeaderResponse,
     HttpInterceptor,
     
     HttpRequest,
     HttpSentEvent
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { ApiRequestHeaderConstants } from './shared/constants/api-request-header-constants';
import { ApiResponseHeaderConstants } from './shared/constants/api-response-header-constants';
import { AppConfig } from './app.config';
import { CookieService } from 'ngx-cookie';
import { IDM_CONSTANTS } from './shared/constants';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { ParameterCustomEncoder } from './parameter-custom-encoder';
import { ParameterCustomEncoderService } from './shared/services/parameter-custom-encoder.service';
import { Router } from '@angular/router';
import { SessionStorageService } from './shared/services/session-storage.service';
import { environmentConstant } from './shared/constants/environment';

@Injectable()
export class RdhInterceptorService implements HttpInterceptor {
    environment = {};
    constructor(private environmentConfig: AppConfig,
        private router: Router,
        private _encoderDecoderService: ParameterCustomEncoderService,
        private _localStorageService: LocalStorageService,
        private _cookieService: CookieService,
        private _sessionStorageService: SessionStorageService) {
    }

    addCommonHttpOptions(request): HttpRequest<any> {
        const params = new HttpParams({encoder: new ParameterCustomEncoder(), fromString: request.params.toString()});
        let clonedParameters: any;
        if (this._sessionStorageService.getCSRFTokenInSessionStorage() !== null) {
            clonedParameters = {params, headers : request.headers
                .set(ApiRequestHeaderConstants.X_XSRF_TOKEN,
                    this._sessionStorageService.getCSRFTokenInSessionStorage())};
        } else {
            clonedParameters = {params};
        }
        let req = request.clone(clonedParameters);
        req.withCredentials = true;
        return req;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent |
    HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const custom_request = this.addCommonHttpOptions(req);
        return next.handle(custom_request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body !== null && event.body.hasOwnProperty(ApiResponseHeaderConstants.XSRF_TOKEN)) {
                        this._sessionStorageService.setCSRFTokenInSessionStorage(
                            event.body[ApiResponseHeaderConstants.XSRF_TOKEN]);
                    }
                }
                return event;
            }),
          catchError( err => {
              if (err.status === 401) {
                  this._cookieService.remove(this.environmentConfig.config[environmentConstant.expirationCookieName]);
                  const currentLocation = location.href;
                  const ReturnUrlArg = IDM_CONSTANTS.RETURN_URL + '=' + encodeURIComponent(currentLocation);
                  window.location.href = this.environmentConfig.config[environmentConstant.idmLogOutUrl] + '?' + ReturnUrlArg;
                  return EMPTY;
              } else if (err.status === 403) {
                  if (err.error.message.is_authorized_for_cluster === false) {
                    this._localStorageService.clearLocalStorageFrUnauthorizedEnvironmentAccess();
                  } else {
                    this.router.navigate(['/home'], { queryParams: { isAuthorized: false,
                        message:
                        this._encoderDecoderService.encodeParameter(JSON.stringify(
                            err.error.message))
                         }});
                    return EMPTY;
                  }
              } else {
                  return throwError(err);
              }
          })
      );
    }
  }
