import {  tap } from 'rxjs/operators';

import { AppConfig } from './app.config';
import { EnvironmentConfig } from './shared/models/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToasterDataService } from './revo-core/toaster-service/toaster-data.service';
import { url } from './api-urls';
import { api_url_constants } from './shared/constants/api-url-constants';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  environment: EnvironmentConfig;
  metadataSchema = '';
  constructor(public http: HttpClient,
    public _toasterDataService: ToasterDataService,
    private config: AppConfig) {
    this.environment = this.config.getConfigObject();
  }

  getAvailableEnvironments(): Observable<any> {
    const httpOptions = { withCredentials: true };
    return this.http.get(
      this.environment.baseUrl +
      api_url_constants.get_environment_details +
      this.environment.metadataSchema,
      httpOptions)
      .pipe(tap(result => result)
      );
  }

  getPesignedDownloadUrlForUserGuide(): Observable<any> {
    return this.http.get<string>(this.environment.baseUrl + url.get_presigned_download_url_for_user_guide)
      .pipe(tap(result => result)
      );
  }
}
