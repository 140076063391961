import {
  AppliedFiltersData,
  selectedFilterColumnData,
  selectedFilterOptionData,
} from '../table-column-filters/table-column-filters-models';
import { Component, ElementRef, OnInit } from '@angular/core';

import { AgFilterComponent } from 'ag-grid-angular';
import { DriverDataDetailConstants } from 'src/app/shared/constants';
import { FilterOpenService } from '../table-column-filters/table-column-filters.service';
import { IDoesFilterPassParams } from 'ag-grid-community';
import { revo_core_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';

@Component({
  selector: 'app-zs-multi-select-dropdown',
  templateUrl: './zs-multi-select-dropdown.component.html',
  styleUrls: ['./zs-multi-select-dropdown.component.less'],
})
export class ZsMultiSelectDropdownComponent
  implements OnInit, AgFilterComponent
{
  enableMultiSelect = true;
  minOptionsForSearch = 0;
  dataSource: any;
  tableName: string;
  PreSelectedOptionValues: Array<AppliedFiltersData>;
  isFilter = false;
  filterClass = '';
  filterId: string;
  filterName: string;

  displayLabelKey = 'key';
  showClearAllOption = false;

  selectedOptionValues = [];
  searchText = '';
  showDropdown = false;
  filterActive = false;

  loadData = { value: false };

  ui_labels = revo_core_labels.MULTI_SELECT_LABELS;
  headerDisplayNameShowLimit =
    DriverDataDetailConstants.headerDisplayNameShowLimit;

  tooltipVisible = true;

  constructor(
    private eRef: ElementRef,
    private filterOpenService: FilterOpenService
  ) {}

  ngOnInit() {
    this.setSelectedOptionValues();
  }

  setSelectedOptionValues() {
    this.PreSelectedOptionValues.forEach((element) => {
      if (element.column_name === this.filterId) {
        this.refreshFilter();
        this.selectedOptionValues.push(...element.value_list);
      }
    });
  }

  openFilter() {
    if (this.showDropdown) {
      const temp = this.eRef.nativeElement.getBoundingClientRect();
      const top = temp.top;
      const left = temp.left;
      const multi = this.eRef.nativeElement.querySelector('.filter-container');
      multi.style.top = top + 'px';
      multi.style.left = left + 'px';
      if (this.dataSource.length === 0) {
        const filterColumnObj = new selectedFilterColumnData();
        filterColumnObj.columnId = this.filterId;
        filterColumnObj.off_set = this.dataSource.length;
        filterColumnObj.tableName = this.tableName;
        this.loadData.value = false;
        this.filterOpenService.setOpenFilter(filterColumnObj);
      }
    }
  }

  HandleOptionSelect(item: any) {
    item.IsSelected = !item.IsSelected;
    if (item.IsSelected) {
      this.selectedOptionValues.push(item.Value);
    } else {
      const index = this.selectedOptionValues.indexOf(item.Value);
      this.selectedOptionValues.splice(index, 1);
    }
    this.filterActive = true;
    this.showDropdown = this.showDropdown && this.enableMultiSelect;
    this.filterOpenService.setLoadFilterData({
      item: item,
      IsSelected: item.IsSelected,
      filterId: this.filterId,
    });
  }

  onScroll() {
    const selectedOPtionsObject = new selectedFilterOptionData();
    selectedOPtionsObject.filterId = this.filterId;
    selectedOPtionsObject.filterText = this.searchText;
    selectedOPtionsObject.off_set = this.dataSource.length;
    this.filterOpenService.setLoadMoreFilterData(selectedOPtionsObject);
  }

  refreshFilter() {
    this.onSearchChange('');
  }

  onSearchChange(value) {
    const resetFilterOptions = true
      ? value.length === 0 || value.length > 0
      : false;

    const selectedOPtionsObject = new selectedFilterOptionData();
    selectedOPtionsObject.filterId = this.filterId;
    selectedOPtionsObject.filterText = value;
    selectedOPtionsObject.off_set = 0;
    selectedOPtionsObject.IsResetFilterOptions = resetFilterOptions;
    this.filterOpenService.setLoadMoreFilterData(selectedOPtionsObject);
  }

  clearSearchText() {
    this.searchText = '';
    this.onSearchChange(this.searchText);
  }

  clearAllFilters() {
    this.loadData.value = true;
    this.filterActive = false;
    this.selectedOptionValues = [];
    this.filterOpenService.setClearAllFilter({
      clearFilter: true,
      filterName: this.filterId,
    });
    this.dataSource.forEach((element) => {
      element.IsSelected = false;
    });
    this.clearSearchText();
  }

  showToolTip() {
    this.tooltipVisible = !this.tooltipVisible;
  }

  // AG FILTER INTERFACE
  agInit(params: any) {
    this.filterClass = params.filterClass;
    this.loadData = params.loadData;
    this.isFilter = params.isFilter;
    this.dataSource = params.dataSource;
    this.displayLabelKey = params.displayLabelKey;
    this.minOptionsForSearch = params.minOptionsForSearch;
    this.filterId = params.filterId;
    this.filterName = params.filterName;
    this.tableName = params.tableName;
    this.showClearAllOption = params.showClearAllOption;
    this.PreSelectedOptionValues = params.PreSelectedOptionValues;
    this.showDropdown = true;
    setTimeout(() => {
      this.openFilter();
    }, 0);
  }

  isFilterActive() {
    return this.filterActive;
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    return true;
  }

  getModel() {
    // pass
  }

  setModel(model: any) {
    // pass
  }

  afterGuiAttached() {
    this.showToolTip();
    setTimeout(() => {
      this.showToolTip();
    }, 0);
  }
}
