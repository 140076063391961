import { Footer } from './footer';

export class EnvironmentConfig {
    production: Boolean;
    baseUrl: String;
    metadataSchema: String;
    idmLogOutUrl: String;
    defaultAvgTimeForBlock: number;
    uiPollingInterval: number;
    ddFileUploadPollingInterval: number;
    paginationValues: Array<any>;
    groupPaginationValues: Array<any>;
    rdh_transient_cluster_hive_db_name: String;
    should_export_audit_columns_for_redshift: boolean;
    dateTimeFormat: any;
    supportedLanguage: Array<string>;
    languageMapping: any;
    maxNavigationTabsCount: number;
    defaultAvgTimeForIdataRun: number;
    expirationCookieName: String;
    hostDomainBlacklistingPattern: String;
    isMDMAdvancedSearchEnabled: boolean;
    heapAppId: String;
    ddUploadCsvMaxFileSize: number;
    ddUploadExcelMaxFileSize: number;
    footer: Footer;
    mdmAffiliationTabsList: any[];
    mdmMaxAddressesToDisplayCount: number;
    AWSRegionNamesList: any[];
    maxPrimaryAttributeToDisplayPerGroup: number;
    isChinaInstance: boolean;
    application: any;
    mdmSourceColorCodes: object;
    hiveToRedshiftDataTypeMapping: any;
    mdmMaxCacheAge: number;
    pageSize: number;
    dagCompilationBuffer: number;
    defaultAverageDurationForProcessExecution: number;
    defaultAverageDurationForFlowExecution: number;
    blacklistedDataCenterQueryTableNames: any;
    supportMailId: string;
    debounceInterval: number;
    disablePaginationDropdownRecordLimit: number;
    showLinearPaginationDropdownThreshold: number;
    defaultPaginationDropdownLimit: number;
    constructor(baseUrl: String,
        metadataSchema: String,
        idmLogOutUrl: String, defaultAvgTimeForBlock: number,
        uiPollingInterval: number, ddFileUploadPollingInterval: number,
        paginationValues: any[], groupPaginationValues: any[], rdh_transient_cluster_hive_db_name: String,
        should_export_audit_columns_for_redshift: boolean, dateTimeFormat: any, supportedLanguage: any, languageMapping: any,
        maxNavigationTabsCount: number, defaultAvgTimeForIdataRun: number, expirationCookieName: String,
        hostDomainBlacklistingPattern: String, isMDMAdvancedSearchEnabled: boolean, heapAppId: String,
        ddUploadCsvMaxFileSize: number, ddUploadExcelMaxFileSize: number,
        footer: Footer, mdmAffiliationTabsList: any[], mdmMaxAddressesToDisplayCount: number,
        AWSRegionNamesList: any[], maxPrimaryAttributeToDisplayPerGroup: number, isChinaInstance: boolean,
        application: object, mdmSourceColorCodes: object,
        hiveToRedshiftDataTypeMapping: any, mdmMaxCacheAge: number, pageSize: number,
        dagCompilationBuffer: number, defaultAverageDurationForProcessExecution: number,
        defaultAverageDurationForFlowExecution: number,
        blacklistedDataCenterQueryTableNames: any, supportMailId: string, debounceInterval: number,
        disablePaginationDropdownRecordLimit: number, showLinearPaginationDropdownThreshold: number, defaultPaginationDropdownLimit: number
        ) {

        this.baseUrl = baseUrl;
        this.metadataSchema = metadataSchema;
        this.idmLogOutUrl = idmLogOutUrl;
        this.defaultAvgTimeForBlock = defaultAvgTimeForBlock;
        this.uiPollingInterval = uiPollingInterval;
        this.ddFileUploadPollingInterval = ddFileUploadPollingInterval;
        this.paginationValues = paginationValues;
        this.groupPaginationValues = groupPaginationValues;
        this.rdh_transient_cluster_hive_db_name = rdh_transient_cluster_hive_db_name;
        this.should_export_audit_columns_for_redshift = should_export_audit_columns_for_redshift;
        this.dateTimeFormat = dateTimeFormat;
        this.supportedLanguage = supportedLanguage;
        this.languageMapping = languageMapping;
        this.maxNavigationTabsCount = maxNavigationTabsCount;
        this.defaultAvgTimeForIdataRun = defaultAvgTimeForIdataRun;
        this.expirationCookieName = expirationCookieName;
        this.hostDomainBlacklistingPattern = hostDomainBlacklistingPattern;
        this.isMDMAdvancedSearchEnabled = isMDMAdvancedSearchEnabled;
        this.heapAppId = heapAppId;
        this.ddUploadCsvMaxFileSize = ddUploadCsvMaxFileSize;
        this.ddUploadExcelMaxFileSize = ddUploadExcelMaxFileSize;
        this.footer = footer;
        this.mdmAffiliationTabsList = mdmAffiliationTabsList;
        this.mdmMaxAddressesToDisplayCount = mdmMaxAddressesToDisplayCount;
        this.AWSRegionNamesList = AWSRegionNamesList;
        this.maxPrimaryAttributeToDisplayPerGroup = maxPrimaryAttributeToDisplayPerGroup;
        this.isChinaInstance = isChinaInstance;
        this.application = application;
        this.mdmSourceColorCodes = mdmSourceColorCodes;
        this.hiveToRedshiftDataTypeMapping = hiveToRedshiftDataTypeMapping;
        this.mdmMaxCacheAge = mdmMaxCacheAge;
        this.pageSize = pageSize;
        this.dagCompilationBuffer = dagCompilationBuffer;
        this.defaultAverageDurationForProcessExecution = defaultAverageDurationForProcessExecution;
        this.defaultAverageDurationForFlowExecution = defaultAverageDurationForFlowExecution;
        this.blacklistedDataCenterQueryTableNames = blacklistedDataCenterQueryTableNames;
        this.supportMailId = supportMailId;
        this.debounceInterval = debounceInterval;
        this.disablePaginationDropdownRecordLimit = disablePaginationDropdownRecordLimit;
        this.showLinearPaginationDropdownThreshold = showLinearPaginationDropdownThreshold;
        this.defaultPaginationDropdownLimit = defaultPaginationDropdownLimit;
    }
}
