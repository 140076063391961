<div class="dropdown">
    <app-zs-select *ngIf="params.data?.isEditable" #gridSelect [(dataSource)]="dataSource"
    [(selected)]="params.value"
    [customStyle]="{ width: '14.2em', 'font-size': '1em'}"
    (selectedChange)="selectedValueChanged($event)"
    [flippable]="true">
    </app-zs-select>
    <div class="zs-margin-1-0-0-0" *ngIf="!params.data?.isEditable">{{ params.value }}</div>   
    
</div>
