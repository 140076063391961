import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  EditableTableService,
} from '../table-column-filters/table-column-filters.service';
import { MultiSelectDropDown } from 'src/app/shared/models/multiSelectDropDown';
import {
  ValueChange,
} from '../table-column-filters/table-column-filters-models';
import { ZsSelectComponent } from '../zs-select/zs-select.component';

@Component({
  selector: 'app-zs-editable-table-select',
  templateUrl: './zs-editable-table-select.component.html',
  styleUrls: ['./zs-editable-table-select.component.less']
})
export class ZsEditableTableSelectComponent
  implements OnInit {
  selectOptions;
  dataSource = [];
  params: any;
  colId: string;
  existingValue: string;
  @ViewChild(' gridSelect ') view: ZsSelectComponent;
  constructor(private _editableTableService: EditableTableService) {}

  ngOnInit(): void {}
  agInit(params: any) {
    this.params = params;
    this.colId = params.colId;
    this.selectOptions = params.dataSource;
    this.existingValue = params.value;
    for (const element of this.selectOptions) {
      const selectObj = new MultiSelectDropDown();
      selectObj.displayLabel = element;
      selectObj.value = element;
      selectObj.isSelected = true ? element === this.params.value : false;
      this.dataSource.push(selectObj);
    }
  }
  selectedValueChanged(value) {
    if (value && value !== this.existingValue) {
      const valueChangeModel = new ValueChange();
      valueChangeModel.HeaderName = this.colId;
      valueChangeModel.Id = this.params.data.rdh_primary_key;
      valueChangeModel.Value = this.params.value;
      this._editableTableService.Add(valueChangeModel);
    }
  }
}
