import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// import { SelfServeRoutingModule } from './self-serve-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { RevoCoreModule } from '../revo-core/revo-core.module';
import { NgZorroAntdModule } from '../ng-zorro-antd.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CommonModule } from '@angular/common';
import { ConfigTableComponent } from './components/config-table/config-table.component';

@NgModule({
  declarations: [ConfigTableComponent],
  imports: [
    CommonModule,
    // SelfServeRoutingModule,
    FormsModule,
    NgZorroAntdModule,
    DragDropModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    NgSelectModule,
    TranslateModule,
    RevoCoreModule,
  ],
  exports: [ConfigTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
