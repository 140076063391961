import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import {
  EditableTableService,
  FilterOpenService,
} from './revo-core/table-column-filters/table-column-filters.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { AppRoutingModule } from './/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieModule } from 'ngx-cookie';
import { CustomLoadingOverlayComponent } from './custom-loading-overlay/custom-loading-overlay.component';
import { DatePipe } from '@angular/common';
import { DomSanitizerPipe } from './shared/custom-pipes/dom-sanitizer-pipe';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RdhInterceptorService } from './RdhInterceptor';
import { RevoCoreModule } from './revo-core/revo-core.module';
import { ToasterDataService } from './revo-core/toaster-service/toaster-data.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZsFilterService } from './revo-core/zs-multi-select-dropdown/zs-filter.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { SharedModule } from './shared/shared.module';
import {
  PlusSquareFill,
  DeleteFill,
  EditFill,
  WarningFill,
  PlusOutline,
  MinusOutline,
  MinusSquareOutline,
  PlusSquareOutline,
  SaveFill,
} from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { NgZorroAntdModule } from './ng-zorro-antd.module';

const icons: IconDefinition[] = [
  PlusOutline,
  PlusSquareFill,
  DeleteFill,
  EditFill,
  WarningFill,
  MinusOutline,
  MinusSquareOutline,
  SaveFill,
];

export function initConfig(config: AppConfig) {
  return () => config.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, CustomLoadingOverlayComponent],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    DragDropModule,
    HttpClientModule,
    FormsModule,
    RevoCoreModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppConfig,
    ToasterDataService,
    EditableTableService,
    ZsFilterService,
    FilterOpenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RdhInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    DatePipe,
    DomSanitizerPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
