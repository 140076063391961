export const ApiRequestConstants = {
  id: 'id',
  cluster_id: 'cluster_id',
  environment_id: 'environment_id',
  cluster_ip: 'cluster_ip',
  process_id: 'process_id',
  table_id: 'table_id',
  process_flow_id: 'process_flow_id',
  job_ids: 'job_ids',
  job_id: 'job_id',
  group_id: 'group_id',
  dd_object_id: 'dd_object_id',
  upload_type: 'upload_type',
  file_extension: 'file_extension',
  dd_file: 'dd_file',
  file_upload_execution_id: 'file_upload_execution_id',
  file_type: 'file_type',
  dd_data_list: 'dd_data_list',
  dd_column_list: 'dd_column_list',
  dd_pk_column_list: 'dd_primary_column_list',
  dd_time_period_id: 'time_period_id',
  s3_file_path: 's3_file_path',
  limitData: 'LimitData',
  isPasswordChanged: 'isPasswordChanged',
  table_permission_level: 'table_permission_level',
  category_id: 'CategoryId',
  cycle_id: 'cycle_id',
  block_id: 'block_id',
  cycle_name: 'cycle_name',
  process_name: 'process_name',
  table_name: 'table_name',
  block_status: 'block_status',
  isBlockDisabled: 'is_block_disabled',
  processRunStatus: 'process_run_status',
  cycleBlockDetails: 'cycle_block_details',
  isBlockPlayDisabled: 'is_block_play_disabled',
  username: 'username',
  hiveMetastoreDetails: 'hiveMetastoreDetails',
  environmentConfiguration: 'environmentConfiguration',
  isDdPasswordChanged: 'isDdPasswordChanged',
  isAzkabanPasswordChanged: 'isAzkabanPasswordChanged',
  isRangerPasswordChanged: 'isRangerPasswordChanged',
  EMRStepsDetails: 'EMRStepsDetails',
  infraDetails: 'infraDetails',
  categoryData: 'CategoryData',
  selected_environment_id: 'selected_environment_id',
  isHandshakeKeyChanged: 'isHandshakeKeyChanged',
  ISINGESTION: 'isIngestion',
  iDataConfiguration: 'iDataConfiguration',
  chartType: 'chartType',
  iDataDomainId: 'iDataDomainId',
  iDataDomain: 'iDataDomain',
  mdmConfiguration: 'MdmConfiguration',
  rdh_process_id: 'rdh_process_id',
  file_download_execution_id: 'file_download_execution_id',
  adaptor_name: 'adaptor_name',
  mdmAttributesDetails: 'MdmAttributesDetails',
  is_transient: 'is_transient',
  page: 'page',
  page_size: 'page_size',
  sort_column: 'sort_column',
  sort_order: 'sort_order',
  showPast: 'show_past',
  sortByColumn: 'sort_by_column',
  sortByOrder: 'sort_by_order',
  schedule_id: 'schedule_id',
  delete_entire_series: 'delete_entire_series',
  searchText: 'search_text',
  currentPage: 'current_page',
  pageSize: 'page_size',
  blob: 'blob',
  backupName: 'BackupName',
  clusterId: 'ClusterId',
  scheduleId: 'ScheduleId',
  categoryRequestDetails: 'CategoryRequestDetails',
  topLevelFilterRequestDetails: 'TopLevelFilterRequestDetails',
  paginationData: 'PaginationData',
  filterData: 'FilterData',
  sortingData: 'SortingData',
  isLocalUploadSave: 'isLocalUploadSave',
  islocalUploadExecute: 'islocalUploadExecute',
  environmentIdCamelCase: 'EnvironmentId',
  isPasswordChangedCamelCase: 'IsPasswordChanged',
  currentPageCamelCase: 'CurrentPage',
  pageSizeCamelCase: 'PageSize',
  searchTextCamelCase: 'SearchText',
  sortByColumnCamelCase: 'SortByCol',
  sortByOrderCamelCase: 'SortByOrder',
  attributesFetched: 'AttributesFetched',
  backupLocation: 'BackupLocation',
  isExpired: 'IsExpired',
  createdAtStartDate: 'CreatedAtStartDate',
  createdAtEndDate: 'CreatedAtEndDate',
  expirationDateStartDate: 'ExpirationDateStartDate',
  expirationDateEndDate: 'ExpirationDateEndDate',
  passwordKeyChanged: 'PasswordKeyChanged',
  customId: 'Id',
  executedBy: 'ExecutedBy',
  customScriptId: 'CustomScriptId',
  startTimeStartDate: 'StartTimeStartDate',
  startTimeEndDate: 'StartTimeEndDate',
  endTimeStartDate: 'EndTimeStartDate',
  endTimeEndDate: 'EndTimeEndDate',
  s3FilePath: 'S3FilePath',
  flowId: 'flow_id',
  flowName: 'flow_name',
  categoryFilterCamelCae: 'CategoryFilter',
  subCategoryFilterCamelCae: 'SubCategoryFilter',
  limitBackups: 'LimitBackups',
  processNameNeeded: 'ProcessNameNeeded',
};

export const ToggleSchedulerRequestConstants = {
  scheduler_id: 'scheduler_id',
};

export const RdbmsDefaultTransactionalValues = {
  format: 'textfile',
  fileType: 'delimiter',
  delimiter: ',',
  quoteCharacter: '\\"',
  escapeCharacter: '\\\\',
};

export const GetRecentProcessesApiRequest = {
  showPast: 'show_past',
  createdBy: 'created_by',
  lastModifiedBy: 'lastModifiedBY',
  processType: 'type',
  limit: 'limit',
  get_drafts: 'draft',
  paginationData: 'pagination_data',
  sortByColumn: 'sort_by_column',
  sortByOrder: 'sort_by_order',
  searchText: 'search_text',
  currentPage: 'current_page',
  pageSize: 'page_size',
  showView: 'show_view',
  dateRange: 'date_range',
  startStartDate: 'startStartDate',
  startEndDate: 'startEndDate',
  endStartDate: 'endStartDate',
  endEndDate: 'endEndDate',
  lastExecutionStartDate: 'lastExecutionStartDate',
  lastExecutionEndDate: 'lastExecutionEndDate',
  createdByStartDate: 'createdByStartDate',
  createdByEndDate: 'createdByEndDate',
  flowIds: 'flow_ids',
  statusValue: 'status_value',
  nextExecutionStartDate: 'nextExecutionStartDate',
  nextExecutionEndDate: 'nextExecutionEndDate',
};

export const GetAllExecutionApiRequest = {
  showPast: 'show_past',
  createdBy: 'created_by',
  processType: 'type',
  limit: 'limit',
  get_drafts: 'draft',
  paginationData: 'pagination_data',
  sortByColumn: 'sort_by_column',
  sortByOrder: 'sort_by_order',
  searchText: 'search_text',
  currentPage: 'current_page',
  pageSize: 'page_size',
  showView: 'show_view',
  dateRange: 'date_range',
  startStartDate: 'startStartDate',
  startEndDate: 'startEndDate',
  endStartDate: 'endStartDate',
  endEndDate: 'endEndDate',
  processId: 'process_id',
  processRunId: 'process_run_id',
  processBlockId: 'process_block_id',
  processBlockStatus: 'statuses',
  blockIds: 'block_ids',
  blockId: 'block_id',
  flowId: 'flow_id',
  flowRunId: 'flow_run_id',
  flowStatus: 'flow_status',
  processName: 'process_name',
  parameters: 'parameters',
  flowBlockIds: 'flow_block_ids',
  jobType: 'job_type',
  isRecurrenceOn: 'is_recurrence_on',
  executionLogStatus: 'execution_log_status',
};

export const ShowPastFilterValues = [
  {
    value: 'ALL',
    displayLabel: 'All',
  },
  {
    value: 'PAST_24_HOURS',
    displayLabel: 'Past 24 Hours',
  },
  {
    value: 'PAST_WEEK',
    displayLabel: 'Past Week',
  },
  {
    value: 'PAST_MONTH',
    displayLabel: 'Past Month',
  },
];

export const ShowPastFilterDefault = 'ALL';
export const CreatedByDefaultValue = 'All';
export const LastModifiedByDefaultValue = 'All'

export const CrticalityValues = {
  CONTINUE_WITH_ERROR: 'continue-with-error',
  CONTINUE_WITH_ERROR_DISPLAY_LABEL: 'Continue ingestion with errorneous data',
  REMOVE_AND_CONTINUE: 'remove-and-continue',
  REMOVE_AND_CONTINUE_DISPLAY_LABEL:
    'Remove errorneous data and continue ingestion',
};

export const StepErrorStatusList = ['ERROR'];
export const HomePageDefaultProcessListCount = 10;

export const ApplicationURLs = {
    pm_process_details_url: '/business-workflows/processes/home'
};

export const ProcessTab = {
  DETAILS: 'details',
  LOG: 'log',
};

export const ProcessMonitoringDetailsTab = {
  FLOW: 'flow',
  TABLE: 'table',
};

export const AzkabanJobStatus = {
  NOT_STARTED: 'NOT_STARTED',
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  INQUEUE: 'INQUEUE',
  KILLED: 'KILLED',
  STOPPED: 'STOPPED',
};

export const ProcessStatus = {
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
};

export const ProcessLogPopupConstants = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const CycleStatus = {
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  INQUEUE: 'INQUEUE',
};

export const CycleStatusDisplayNameMapping = {
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export const OozieStatus = {
  SUBMITTED: 'SUBMITTED',
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  SUSPENDED: 'SUSPENDED',
  KILLED: 'KILLED',
  INQUEUE: 'INQUEUE',
  INPROGRESS: 'INPROGRESS',
};

export const ProcessAndFlowStatusConstants = {
  SUBMITTED: 'SUBMITTED',
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  SUSPENDED: 'SUSPENDED',
  KILLED: 'KILLED',
  INQUEUE: 'INQUEUE',
  INPROGRESS: 'INPROGRESS',
  KILLING: 'KILLING',
  RETRYING: 'RETRYING',
};

export enum TabNames {
  MasterData = 'MasterData',
  MappingFiles = 'MappingFiles',
  Cards = 'Cards',
  Table = 'Table',
}

export enum PermissionRights {
  Read = 'READ',
  Write = 'WRITE',
}

export enum PermissionRightsUserFriendlyName {
  Read = 'View',
  Write = 'Edit',
}
export const IngestionExtractionPeriodList = {
  ALL: 'ALL',
  PAST_24_HOURS: 'PAST_24_HOURS',
  PAST_7_DAYS: 'PAST_WEEK',
  PAST_30_DAYS: 'PAST_MONTH',
};

export const DriverDataFileUploadMethod = {
  Insert: 'INSERT',
  InsertOverwrite: 'INSERT_OVERWRITE',
};

export const DriverDataDetailConstants = {
  DDPrimaryKey: 'rdh_primary_key',
  ActiveVersion: 'Active',
  DDColumnNameKey: 'column_name',
  DDColumnDisplayKey: 'display_name',
  DDPrimaryKeyIndicatorKey: 'is_primary_key',
  DDSelectOptionKey: 'dropdown_options',
  DDIsEditable: 'isEditable',
  DDColumnIsRequired: 'is_required',
  DDSelectKey: 'select_key',
  DDIsFilterable: 'is_filterable',
  discardUpdatedDataWarningMessage:
    'You have unsaved changes on this page. Do you want to save the changes before leaving, or discard them?',
  recordDeletedSuccessMessage: 'Record deleted successfully',
  selectAllColumnName: 'selectAll',
  autoCompleteSearchTextKey: 'search_text',
  autoCompleteSearchReferredColumnId: 'referred_column_id',
  DDAutoCompleteSelectorID: 'zs_cell_autocomplete',
  driverDataObjectNameShowLimit: 50,
  headerDisplayNameShowLimit: 24,
  DDDqmChecks: 'dqm_checks',
};

export const DriverDataAppliedFilterConstants = {
  FilterValueListKey: 'value_list',
  FilterColumnNameKey: 'column_name',
};

export const DriverDataOverViewConstants = {
  cardHeaderTextShowLimit: 25,
  cardDescriptionShowLimit: 72,
  subcategoryNameCharacterLimit: 16,
  categoryNameCharacterLimit: 20,
  sidebarCategoriesLimit: 5,
  sourceShowLimit: 19,
  ownerShowLimit: 19,
  threeDotsConstant: '...',
};

export const DriverDataTableOverviewColumnWidthConstants = {
  display_name: 250,
  description: 400,
  last_modified_at: 340,
  owner: 180,
  source: 180,
};

export const DriverDataErrorLogConstants = {
  showErrorMessageLength: 500,
  downloadLinkLabel: 'Download error log',
  fileName:
    'error_log_' +
    new Date().toLocaleDateString() +
    '_' +
    new Date().getMilliseconds() +
    '.txt',
};

export const DriverDataAccessDeniedErrorConstants = {
  data_columns: 'data_columns',
  primary_columns: 'primary_columns',
};

export const ErrorToasterConstants = {
  MAX_TIMEOUT: 1000000,
  NO_ENVIRONMENT_SELECTED_MSG: 'No environment selected',
  UNSUPPORTED_BROWSER:
    'For the best experience, please use Google Chrome or Microsoft Edge Chromium.',
  NO_PROCESS_PAGE_FOUND: 'NO_PROCESS_PAGE_FOUND',
};

export const FOOTERCOLORCONSTANTS = [
  '#86C8BC',
  '#00629B',
  '#6E2B62',
  '#B8CC7B',
  '#01A6DC',
  '#A3B2AA',
];

export const ALLOWED_FILE_TYPES = {
  DRIVER_DATA_UPLOAD_FORMAT: ['.xls', '.xlsx', 'csv'],
  DRIVER_DATA_EXCEL_FORMATS: ['xls', 'xlsx'],
  DRIVER_DATA_CSV_FORMATS: ['csv'],
  ADAPTER_UPLOAD_FORMAT: ['.xls', '.xlsx'],
  PM_TEMPLATE_UPLOAD_FORMAT: ['.xls', '.xlsx'],
  BRM_DATA_UPLOAD_FORMAT: ['.xls', '.xlsx'],
  LOCAL_UPLOAD: ['.xls', '.txt', '.xlsx', '.csv', '.parquet'],
  GLOBAL_PARAMETER_UPLOAD: ['.xls', '.xlsx'],
};

export const FILE_TYPE_EXTENSIONS_MAPPING = {
  excel: ['.xls', '.xlsx'],
  parquet: ['.parquet'],
  textfile: ['.txt', '.csv'],
};

export const FILE_TYPE_CONSTANT = {
  CSV: 'csv',
  EXCEL: 'excel',
};

export const IDM_CONSTANTS = {
  IS_USER_TRIGGERED: 'is_user_triggered',
  RETURN_URL: 'ReturnUrl',
};

export const DateTimeConstants = {
  Z: 'Z',
  UTC: 'UTC',
  DateTimeFormat: 'MM/dd/yyyy HH:mm:ss',
  DateFormat: 'MM/dd/yyyy',
};

export const ExtractionJsonConstants = {
  exportQueryTypeCustom: 'custom',
  exportQueryTypeMapping: 'mapping',
  ExportDataScheduleRdbms: 'NA',
  ExportDataOverwriteDestinationFlatFile: 'true',
};

export const RDMJsonConstants = {
  DataTypeRdbms: 'DATA_INGESTION',
  DataRequestType: 'add',
  DQM: 'DQM',
};

export const DqmJsonConstants = {
  InterColumnSeparator: '#',
  IntraColumnSeparator: '^',
};

export const HiveToRedshiftDataTypeMapping = {
  int: 'integer',
  bigint: 'bigint',
  double: 'float',
  decimal: 'double precision',
  boolean: 'boolean',
  string: 'varchar(max)',
  timestamp: 'timestamp',
};

export const RedshiftIngestExtractConstants = {
  valid_table_suffix: '_valid',
  in_valid_table_suffix: '_invalid',
  processed_table_suffix: '_processed',
  valid: 'valid',
  invalid: 'invalid',
};

export const IngestionFileLoadType = {
  full: 'full',
  update_incremental: 'updateincremental',
};

export const IngestionTransactionType = {
  dimensional: 'dimensional',
  transactional: 'transactional',
};

export const IngestionAuditColumnList = [
  'batch_id',
  'process_id',
  'load_time',
  'modified_date',
  'modified_flag',
  'source_file_name',
  'source_row_id',
];

export const IngestionAuditColumnsWithRowIdList =
  IngestionAuditColumnList.concat(['row_id']);

export const IngestionAuditColumnWithDataTypeList = [
  'batch_id varchar',
  'process_id varchar',
  'load_time varchar',
  'modified_date varchar',
  'modified_flag varchar',
  'source_file_name varchar',
  'source_row_id varchar',
];

export const IngestionInvalidTableColumnList = [
  'immdt_source_name',
  'immdt_src_row_id',
  'ult_source_name',
  'ult_src_row_id',
  'trgt_tbl',
  'load_dt',
  'batch_id',
  'col_nm',
  'dqm_chk_typ',
  'crtcl_flg',
  'expctd_val',
  'actl_val',
  'error_msg',
];

export const IngestionInvalidTableColumnWithDataTypeList = [
  'immdt_source_name varchar',
  'immdt_src_row_id varchar',
  'ult_source_name varchar',
  'ult_src_row_id varchar',
  'trgt_tbl varchar',
  'load_dt varchar',
  'batch_id varchar',
  'col_nm varchar',
  'dqm_chk_typ varchar',
  'crtcl_flg varchar',
  'expctd_val varchar',
  'actl_val varchar',
  'error_msg varchar',
];

export enum TableType {
  PRIMARY_TABLE,
  VALID,
  INVALID,
  PROCESSED,
}

export const uuid_prefix = 'rdh_';

export const PROCESS_UI_CONSTANTS = {
  ingExtProcessNameShowLimit: 22,
  processNameShowLimit: 40,
  homePageProcessNameShowLimit: 25,
  tableNameShowLimit: 35,
};

export const ADAPTOR_SETTING_TYPE = {
  New: 'New',
  LoadSetting: 'LoadSetting',
};

export const ApprovalRequestStatus = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
};

export const AdaptorDetailsConstants = {
  ID: 'id',
  INDEX_ID: 'index_id',
  IS_USER_CREATED: 'is_user_created',
  LAST_MODIFIED_AT: 'last_modified_at',
  LAST_MODIFIED_BY: 'last_modified_by',
  SOURCE_ID: 'source_id',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  IS_DELETED: 'is_deleted',
  DATA: 'data',
  ADAPTOR_NAME: 'AdaptorName',
  ADAPTOR_TYPE: 'AdaptorType',
  TYPE: 'type',
};

export class SelectDropDown {
  displayLabel: string;
  value: any;
}

export const SortConstants = {
  ASC: 'asc',
  DESC: 'desc',
};

export const LanguageConstants = {
  DEFAULT_LANGUAGE: 'en-US',
  SELECTED_LANGUAGE: 'selectedLanguage',
  LANGUAGE_MAPPINGS: {
    en: 'ENGLISH',
    'en-US': 'ENGLISH_US',
    es: 'SPANISH',
    'es-419': 'SPANISH_LATIN_AMERICA',
    de: 'GERMAN',
    'de-DE': 'GERMAN_DE',
    ja: 'JAPANESE',
    'pt-BR': 'PORTUGUESE_BRAZIL',
    'zh-CN': 'CHINESE_SIMPLIFIED',
  },
};

export const NavigationTabs = {
  HOME: 'Home',
  DRIVER_DATA: 'Driver Data',
  BUSINESS_RULES: 'Business Rules',
  PROCESSES: 'Processes',
  AUDIT_LOG: 'Audit Log',
  ADMIN: 'Admin',
  SITE_ADMIN: 'Site Admin',
  IMPORT_EXPORT: 'Import/Export',
  MASTER: 'Master',
  DATA: 'Data',
  SETTINGS: 'Settings',
  DATA_QUERY: 'Data Query',
  MASTER_DATA: 'Master Data',
  DATA_CENTER: 'Data Center',
  PROFILE: 'Profile',
  HELP: 'Help',
  APPS: 'Apps',
  ADAPTORS: 'Adaptors',
  FLOWS: 'Flows',
  PROCESS_EXECUTION: 'Process Execution',
  COMPONENTS: 'Components',
  BUISSNESS_WORKFLOW: 'Business Workflows'
};

export const NavigationIcon = {
  [NavigationTabs.HOME]: 'zs-icon-home-fill',
  [NavigationTabs.BUSINESS_RULES]: 'brm-icon',
  [NavigationTabs.BUISSNESS_WORKFLOW]: 'process-icon',
  [NavigationTabs.AUDIT_LOG]: 'audit-logs-icon',
  [NavigationTabs.ADMIN]: 'zs-icon-settings-fill',
  [NavigationTabs.SITE_ADMIN]: 'site-admin-icon',
  [NavigationTabs.IMPORT_EXPORT]: 'import-export-icon',
  [NavigationTabs.DATA]: 'zs-icon-data',
  [NavigationTabs.SETTINGS]: 'zs-icon-settings-fill',
  [NavigationTabs.DATA_QUERY]: '',
  [NavigationTabs.MASTER_DATA]: 'master-data-icon',
  [NavigationTabs.DRIVER_DATA]: '',
  [NavigationTabs.DATA_CENTER]: '',
  [NavigationTabs.PROFILE]: 'zs-icon-user-fill',
  [NavigationTabs.HELP]: 'zs-icon-help-fill',
  [NavigationTabs.APPS]: 'zs-icon-app-nav',
  [NavigationTabs.ADAPTORS]: 'adaptor-icon',
};

export const RouterLinkMapper = {
  [NavigationTabs.HOME]: '/home',
  [NavigationTabs.DRIVER_DATA]: '/driver-data',
  [NavigationTabs.BUSINESS_RULES]: '/brm',
  [NavigationTabs.DATA_CENTER]: '/data-center',
  [NavigationTabs.DATA_QUERY]: '/data-query',
  [NavigationTabs.PROCESSES]: '/business-workflows/processes/home',
  [NavigationTabs.AUDIT_LOG]: '/audit-log',
  [NavigationTabs.ADMIN]: '/admin',
  [NavigationTabs.SITE_ADMIN]: '/site-admin',
  [NavigationTabs.IMPORT_EXPORT]: '/import-export',
  [NavigationTabs.MASTER]: '/mdm',
  [NavigationTabs.ADAPTORS]: '/adaptors',
  [NavigationTabs.FLOWS]: '/business-workflows/flows/home',
  [NavigationTabs.PROCESS_EXECUTION]: '/business-workflows/processes/all-executions/',
  [NavigationTabs.COMPONENTS]: '/business-workflows/components/home',
};

export const RouterDetailsConstant = {
  ROUTER_LINK: 'routerLink',
  NAVIGATION_TAB_NAME: 'navigationTabName',
  NAVIGATION_TAB_VALUE: 'navigationTabValue',
  NAVIGATION_ICON: 'navigationIcon',
};

export const RouterLinkDetailsForDriverData = {
  DRIVER_DATA_EDIT: '/driver-data/detail/',
};

export const RouterLinkDetailsForDataCenter = {
  DATA_CENTER_HOME: '/data-center/home',
  DATA_CENTER_EDIT: '/data-center/edit/',
  DATA_CENTER_PAST_RUNS: '/data-center/past-runs/',
  DATA_CENTER_CREATE: '/data-center/create/',
  DATA_CENTER_LOCAL_UPLOAD: '/data-center/upload/',
  DATA_CENTER_LINKED_FLOWS_EXECUTION_PATH: '/business-workflows/flows/all-executions/',
  FLOW_EXECUTION: '/run/',
};

export const RouterLinkDetailsForDataQuery = {
  DATA_QUERY_HOME: '/data-query/home',
  DATA_QUERY_EDIT: '/data-query/edit/',
  DATA_QUERY_PAST_RUNS: '/data-query/past-runs/',
  DATA_QUERY_CREATE: '/data-query/create/',
};

export const bottomNavigationList = [
  {
    name: NavigationTabs.PROFILE,
    [RouterDetailsConstant.NAVIGATION_ICON]:
      NavigationIcon[NavigationTabs.PROFILE],
  },
  {
    name: NavigationTabs.APPS,
    [RouterDetailsConstant.NAVIGATION_ICON]:
      NavigationIcon[NavigationTabs.APPS],
  },
  {
    name: NavigationTabs.HELP,
    [RouterDetailsConstant.NAVIGATION_ICON]:
      NavigationIcon[NavigationTabs.HELP],
  },
];

export const ExcelSheetNameConstants = {
  AUDIT_LOG: 'AuditLog',
  MIGRATION: 'Migration',
  GLOBAL_PARAMETERS: 'Global Parameters',
};

export const IngestionTypesForUI = {
  ALL: 'All',
  SMART_INGESTION: 'Smart Ingestion',
  SMART_ANALYSIS: 'Smart Analysis',
  INGESTION: 'Ingestion',
};

export const IngestionTypes = {
  INGESTION: 'INGESTION',
  IDATA: 'IDATA',
  REDSHIFT_INGESTION: 'REDSHIFT_INGESTION',
  INGESTION_WITH_IDATA: 'INGESTION_WITH_IDATA',
  REDSHIFT_INGESTION_WITH_IDATA: 'REDSHIFT_INGESTION_WITH_IDATA',
  ALL: 'ALL',
};

export const ProcessParamKeys = {
  NAME: 'name',
  IS_MANDATORY: 'is_mandatory',
  VALUE: 'value',
  VARIABLE_TYPE: 'variable_type',
  FORMAT: 'format',
  DEFAULT_VALUE: 'default_value',
  LOOKUP_VALUES: 'lookup_values',
  DATA_TYPE: 'data_type',
};

export const ProcessParamVariables = {
  CURRENT_DATE: 'current_date',
  CURRENT_CYCLE: 'current_cycle',
  LOOKUP: 'lookup',
};

export const ProcessParamDataTypes = {
  STRING: 'string',
  DATE: 'date',
};

export const RdhProcessPMProcessMappingKeys = {
  PM_PROCESS_ID: 'pm_process_id',
  CYCLE_NAME: 'cycle_name',
  PM_PARAMETERS: 'pm_parameters',
};

export const RdhProcessFlowMappingKeys = {
  FLOW_ID: 'flow_id',
  CYCLE_NAME: 'cycle_name',
  PARAMETERS: 'parameters',
  TEMPLATE: 'template',
  FLOW_NAME: 'flow_name',
  ID: 'id',
  LAST_UPDATED_AT: 'last_updated_at'
};

export const ProcessMonitoringConstants = {
  PROCESS_NAME_SHOW_LIMIT: 21,
  PROCESS_PARAM_NAME_SHOW_LIMIT: 21,
  PROCESS_CYCLE_NAME_SHOW_LIMIT: 21,
};

export const RedshiftConstants = {
  SCHEMA_NAME_SHOW_LIMIT: 33,
  DATABASE_NAME_SHOW_LIMIT: 33,
  ADAPTOR_NAME_SHOW_LIMIT: 33,
};

export const DateFormatsRegex = {
  REGEX_MAPPING: {
    'DD-MM-YYYY': '^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\\d{4}$',
    'MM-DD-YYYY': '^(0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[-]\\d{4}$',
    'YYYY-MM-DD': '^\\d{4}[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$',
    'YYYY-DD-MM': '^\\d{4}[-](0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])$',
    'DD/MM/YYYY': '^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\\d{4}$',
    'MM/DD/YYYY': '^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/]\\d{4}$',
    'YYYY/MM/DD': '^\\d{4}[/](0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])$',
    'YYYY/DD/MM': '^\\d{4}[/](0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])$',
  },
};

export const DateFormats = {
  DATE_FORMATS_MAPPING: {
    'DD-MM-YYYY': 'dd-MM-yyyy',
    'MM-DD-YYYY': 'MM-dd-yyyy',
    'YYYY-MM-DD': 'yyyy-MM-dd',
    'YYYY-DD-MM': 'yyyy-dd-MM',
    'DD/MM/YYYY': 'dd/MM/yyyy',
    'MM/DD/YYYY': 'MM/dd/yyyy',
    'YYYY/MM/DD': 'yyyy/MM/dd',
    'YYYY/DD/MM': 'yyyy/dd/MM',
  },
};

export const LOAD_SETTING_VALUE = {
  SELECT: 'select',
};

export const EDITABLE_TABLE_OPTION = {
  SELECT_OPTION: 'Select option',
};

export const MDMActivityLogAppliedFilterConstants = {
  FilterValueListKey: 'value_list',
  FilterColumnNameKey: 'column_name',
};

export const StepperStepStatusConstant = {
  PENDING: 'PENDING',
  CANCEL_PENDING: 'CANCEL_PENDING',
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  INTERRUPTED: 'INTERRUPTED',
};

export const FilterConstants = {
  FilterValueListKey: 'value_list',
  FilterColumnNameKey: 'column_name',
};

export const GlobalParameterConstants = {
  type_filter_list: 'type_filter_list',
  DISPLAY_LABEL: 'displayLabel',
  TYPE: 'type',
  PASSWORD: 'password',
  DATA: 'Data',
};

export const mdmSourceColorCodes = {
  veeva: '#00629b',
  shs: '#86c8bc',
  medpro: '#6e2b62',
  vadcr: '#b8cc7b',
  vudcr: '#01a6dc',
  iqvia: '#a3b2aa',
  xyz: '#a0afc6',
  sd: '#00629b',
  sha: '#86c8bc',
};

export const DriverDataDownloadTemplateConstants = {
  FileName: 'DriverData_Object_Creation_Template',
};

export const DDTableColumnConstants = {
  ['Driver Data Name']: 'display_name',
  ['Description']: 'description',
  ['Source']: 'source',
  ['Owner']: 'owner',
  ['Last Updated']: 'last_modified_at',
};

export const DefineSchemaConstants = {
  DefineSchema: 'DefineSchema',
  displaySchema: 'displaySchema',
  timeStamp: 'Timestamp',
  Decimal: 'Decimal',
  sourceDefaultFormat: '(,)',
  fixedWidth: 'fixedWidth',
  columnWidth: 'ColumnWidth',
  dataType: 'DataType',
  alias: 'Alias',
};

export const S3ServiceConstant = {
  FILE: 'file',
};

export const CheckFileValidatiomsConstant = {
  SCHEMAREQUESTDETAILS: 'schemaRequestDetails',
  SCHEMADETAILS: 'schemaDetails',
};

export const CycleSelectionConstant = {
  NEW_CYCLE: 'New Cycle',
  SELECT: 'select',
  MAPPED_CYCLE: 'Mapped Cycle',
};

export const AllExecutionPeriodList = {
  ALL: 'ALL',
  PAST_24_HOURS: 'PAST_24_HOURS',
  PAST_7_DAYS: 'PAST_WEEK',
  PAST_30_DAYS: 'PAST_MONTH',
};

export const IDM_Class_CONSTANTS = {
  LEGACY_DIV: '#lbr',
  NON_LEGACY_DIV: '#zs-lbr',
  LAST_LOGIN_DIV: '#lbar-lastlogin',
  RENDERED_CHECK_CLASS: 'rendered',
  LOGOUT_DIV: 'lbl_so',
  LAST_LOGIN_LOCAL: 'lastLoginLocal',
  DATE_TIME_FORMAT: 'd/M/yyyy, h:m:ss a',
};

export const GetCustomScriptApiRequest = {
  FILTERS: 'DateFilters',
  PAGE: 'Page',
  PAGE_NUMBER: 'PageNumber',
  SORT_BY_COLUMN: 'SortByColumn',
  DESC: 'DESC',
  SHOW_PAST: 'ShowPast',
  CUSTOM_SCRIPT_NAME: 'CustomScriptName',
};

export const CustomScriptApiRequestConstants = {
  S3_FILE_PATH: 'S3FilePath',
  IS_BACKUP: 'IsBackup',
};

export const NavBarActiveTabMapper = {
  ['home']: NavigationTabs.HOME,
  ['brm']: NavigationTabs.BUSINESS_RULES,
  ['site-admin']: NavigationTabs.SITE_ADMIN,
  ['business-workflows']: NavigationTabs.BUISSNESS_WORKFLOW,
  ['admin']: NavigationTabs.SETTINGS,
  ['audit-log']: NavigationTabs.AUDIT_LOG,
  ['import-export']: NavigationTabs.IMPORT_EXPORT,
  ['adaptors']: NavigationTabs.ADAPTORS,
  ['mdm']: NavigationTabs.MASTER_DATA
};

export const VirtualEnvironmentApiRequest = {
  VIRTUAL_ENVIRONMENT_ID: 'VirtualEnvironmentId',
  VIRTUAL_ENVIRONMENT_NAME: 'VirtualEnvironmentName',
  S3_FILE_PATH: 'S3FilePath',
  DESCRIPTION: 'Description',
  REQUIREMENTS_FILE_PATH: 'RequirementsFilePath',
  IS_EDIT: 'IsEdit',
};

export const ProcessRunsFlowConstants = {
  flowNameTextShowLimit: 20,
  ellipsis: '...',
};
