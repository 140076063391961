import { NgModule } from '@angular/core';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

// import { NzAlertModule } from 'ng-zorro-antd/alert';
// import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
// import { NzRadioModule } from 'ng-zorro-antd/radio';
// import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
// import { NzDrawerModule } from 'ng-zorro-antd/drawer';
// import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@NgModule({
  exports: [
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzModalModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSpinModule,
    NzTableModule,
    NzTabsModule,
    NzToolTipModule,
  ],
})
export class NgZorroAntdModule {}
